<template>
  <div class="ClientService">
    <div class="app-content content ">
      <div class="content-wrapper container-xxl p-0">
        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
              <div class="row breadcrumbs-top">
                  <div class="col-12">
                      <h2 class="content-header-title float-start mb-0">Client Services</h2>
                      <div class="breadcrumb-wrapper">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a href="index.html">Home</a>
                              </li>
                              <li class="breadcrumb-item active">Client Service View
                              </li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="content-body">
          <div class="row" id="basic-table">
              <div class="col-12">
                  <div class="card">
                      <div class="card-header">
                          <h4 class="card-title">Client Service View</h4>
                      </div>
                      <div class="card-body">
                          <!-- <p class="card-text">
                              Using the most basic table Leanne Grahamup, here’s how <code>.table</code>-based tables look in Bootstrap. You
                              can use any example of below table for your table and it can be use with any type of bootstrap tables.
                          </p> -->
                      </div>
                      <div class="table-responsive p-2">
                      
                        <div class="row mt-1" v-if="clientservice.client">
                          <h3>Client Personal Info</h3><hr>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Code</strong> : {{clientservice.client.code}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Name</strong> : {{clientservice.client.fname}} {{clientservice.client.lname}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Email</strong> : {{clientservice.client.email}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Phone</strong> : {{clientservice.client.phone}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>DOB</strong> : {{clientservice.client.dob}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Gender</strong> : {{clientservice.client.gendor}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Father Name</strong> : {{clientservice.client.fatherName}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Address </strong> : {{clientservice.client.address}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Address 2</strong> : {{clientservice.client.address2}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>State</strong> : {{clientservice.client.state.name}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>City</strong> : {{clientservice.client.city}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><strong>Pin</strong> : {{clientservice.client.pin}}</div>
                        </div>

                        <div class="row mt-1" v-if="clientKyc.id">
                          <h3>Client KYC Info</h3><hr>
                          <div class="col-12 col-md-4 col-lg-4"><b>Aadhar card NO</b> : {{clientKyc.aadharNo}}</div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Aadhar card Photo</b> : 
                            <div class="avatar-group">
                                <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar pull-up my-0" title="Aadhar Front">
                                    <img :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + clientKyc.aadharFrontPhoto" @click="viewImage(clientKyc.aadharFrontPhoto)" alt="Avatar" height="30" width="30" />
                                </div>
                                <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar pull-up my-0" title="Aadhar Back">
                                    <img :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + clientKyc.aadharBackPhoto" @click="viewImage(clientKyc.aadharBackPhoto)" alt="Avatar" height="30" width="30" />
                                </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Aadhar card Status</b> : {{clientKyc.aadharStatus}}</div>

                          <div class="col-12 col-md-4 col-lg-4"><b>Pan card NO</b> : {{clientKyc.panNo}}</div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Pan card Photo</b> :
                            <div class="avatar-group">
                                <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar pull-up my-0" title="Pancard Photo">
                                    <img :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + clientKyc.panPhoto" @click="viewImage(clientKyc.panPhoto)" alt="Avatar" height="30" width="30" />
                                </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Pan card Status</b> : {{clientKyc.panStatus}}</div>

                          <div class="col-12 col-md-4 col-lg-4"><b>Account NO</b> : {{clientKyc.accountNo}} {{clientKyc.accountType}}</div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Passbook/Check Book Photo</b> :
                            <div class="avatar-group">
                                  <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar pull-up my-0" title="Passbook Photo">
                                      <img :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + clientKyc.passbookPhoto" @click="viewImage(clientKyc.passbookPhoto)" alt="Avatar" height="30" width="30" />
                                  </div>
                                  <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar pull-up my-0" title="Check Book Photo">
                                      <img :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + clientKyc.checkPhoto" @click="viewImage(clientKyc.checkPhoto)" alt="Avatar" height="30" width="30" />
                                  </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-4 col-lg-4"><b>Account Status</b> : {{clientKyc.accountStatus}}</div>
                        </div>

                        <div class="row mt-1" v-if="service.id">
                          <h3>Client Service Details</h3><hr>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Name</b> : {{service.bnusinessName}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Pan No</b> : {{service.businessPanNo}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Incorporation Date</b> : {{service.businessIncorporationDate}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Satate</b> : {{service.bnusinessName}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business City</b> : {{service.businessCity}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Address</b> : {{service.businessAddress}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Address2</b> : {{service.businessAddress2}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Business Pin</b> : {{service.businessPin}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>ITR Type</b> : {{service.itrType}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Source Of Income</b> : {{service.sourceOfIncome}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>ITR Password</b> : {{service.itrPassword}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Status</b> : {{service.status}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Remark</b> : {{service.remark}}</div>

                          <div class="col-12 col-md-3 col-lg-3"><b>Pan Card File</b> : {{service.businessPanFile}}</div>
                          <div class="col-12 col-md-3 col-lg-3"><b>Previous Year Financial Statement (Audit Report)</b> : {{service.lastYearFinancialStatementFile}}</div>
                        </div>

                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="modal fade" id="showModalCenter" tabindex="-1" aria-labelledby="showModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalCenterTitle">View Document</h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div class="modal-body">
                        <img style="width:100%;"
                            class="img-responsive"
                            :src="this.$store.state.imgUrl + '/client/' + clientKyc.client_id + '/thumbs/' + photo"
                            alt="User profile picture"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" data-bs-dismiss="modal" class="btn btn-danger"><i data-feather='arrow-left'></i> CLOSE</button>
                        
                        <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Accept</button> -->
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
let $ = jQuery
export default {
  name: 'ClientServiceView',
  data() {
    return {
      // termCondition: 'false1'
      clientservice: {},
      clientKyc: {},
      service: {},
      sourceOfIncome: null,
      photo: ''
    }
  },
  methods: {
    viewImage(photo) {
        this.photo = photo
        $('#showModalCenter').modal('show')
    },
    getData () {
    
        this.$axios
        .get(`retailer/clientservice/${this.$route.params.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
        .then(res => {
          this.clientservice = res.data.data          
          this.$axios
            .get(`retailer/clientkyc?client_id=${this.clientservice.client.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
            .then(res => {
              this.clientKyc = res.data.data.data[0]
            })
          this.$axios
            .get(`retailer/itrfilling?client_service_id=${this.clientservice.id}`, {headers: { Authorization: 'Bearer ' + localStorage.accessToken }})
            .then(res => {
              this.service = res.data.data.data[0]
              this.sourceOfIncome = JSON.parse(this.service.sourceOfIncome)
            })
        })
        .catch((error) => {
            error = error.data.message
            console.log(error)
        })
    },
  },
  created() {
    if(this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null){
      this.$router.push({name: 'Login'})
    } else {
      this.getData()
    }
  },
}
</script>

<style scoped>
</style>